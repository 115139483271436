import "./main.scss"
import * as ElmDebugger from "elm-debug-transformer"
import { Elm } from "./Main.elm"

ElmDebugger.register()

const app = Elm.Main.init({
  node: document.getElementById("root"),
  flags: {
    session: JSON.parse(localStorage.getItem("session")) || null,
    apiUrl: process.env.ELM_APP_API_URL,
    forgeApiUrl: { forgeApiUrl: process.env.ELM_APP_API_URL },
  },
})

app.ports.readLocalStorage.subscribe((key) => {
  app.ports.onDidReadLocalStorage.send({
    [key]: JSON.parse(localStorage.getItem(key)),
  })
})

app.ports.writeToLocalStorage.subscribe((data) => {
  Object.entries(data).forEach(([key, value]) =>
    localStorage.setItem(key, JSON.stringify(value))
  )

  // app.ports.onDidWriteToLocalStorage.send(JSON.stringify(data));
})

app.ports.requestSaveSessionToLocalStorage.subscribe((session) => {
  localStorage.setItem("session", JSON.stringify(session))

  app.ports.onSessionSavedToLocalStorage.send(session)
})

app.ports.requestDeleteSessionFromLocalStorage.subscribe(() => {
  const session = localStorage.getItem("session")
  if (typeof session === "string") {
    localStorage.removeItem("session")

    app.ports.onSessionDeletedFromLocalStorage.send(null)
  }
})
